import Button from "@/components/Common/Buttons/Button/Button";
import InputField from "@/components/Common/Inputs/Input/Input";
import FileInput from "@/components/Common/Inputs/FileInput/FileInput";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import YesNoInput from "@/components/Common/Inputs/YesNoInput/YesNoInput";
import MakePremium from "@/components/Components/MakePremium/MakePremium";
import PremiumAdSelection from "@/components/Components/PremiumAdSelection/PremiumAdSelection";
import AdminVendorListItem from "@/components/Components/AdminVendorListItem/AdminVendorListItem";
import CreditWallet from "@/components/Components/CreditWallet/CreditWallet";
import AdUpdate from "@/components/Components/AdUpdate/AdUpdate";
import Pagination from "@/components/Components/Pagination/Pagination";
import UserTypeMobile from "@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile";
import UserNavMobile from "@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile";


export default {
    name: 'AdminVendorOrders',
    components: {

        Button,
        CheckBox,
        MakePremium,
        AdUpdate,
        PremiumAdSelection,
        CreditWallet,
        AdminVendorListItem,
        TextArea,
        Dropdown,
        InputField,
        UserTypeMobile,
        UserNavMobile,
        YesNoInput,
        FileInput,
        Pagination
    },
    data() {
        return {
            filterBoxActive: false,
            activeInactiveSwitch: 'active',

            sortingOptions: [
                {
                    title: this.$t('price-ascending'),
                    value: 'price_ascending',
                },
                {
                    title: this.$t('price-descending'),
                    value: 'price_descending',
                },
                {
                    title: this.$t('create-date'),
                    value: 'create_date',
                },
            ],
            selectedOption: {
                title: this.$t('create-date'),
                value: 'create_date',
            },
            orderStatuses: {
                'all-statuses': null,
                'order-status-awaiting-payment': 0,
                'order-status-paid': 1,
                'order-status-received': 2,
                'order-status-confirmed': 3,
                'order-status-delivered': 4,
            },
            offset: 0,
            limit: 10,
            selectedFilter: null,
            orders: [],
            totalOrdersCount: null
        }
    },
    watch: {
        selectedFilter: async function (newVal, oldVal) {
            await this.fetchOrders(newVal);
        }
    },
    methods: {
        async handlePageChange(page) {
            this.offset = page * this.limit;
            await this.fetchOrders();
        },
        getOrderStatusNameById(id) {
            let status = '';
            Object.keys(this.orderStatuses).forEach((key) => {
                if (this.orderStatuses[key] === parseInt(id)) {
                    status = key;
                }
            });

            return status;
        },
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        },
        selectOption(option) {
            this.toggleFilterBox();
            this.selectedOption = option;
        },
        async fetchOrders(status = null) {
            const offset = this.offset;
            const limit = this.limit;
            if (status === 'all-statuses') {
                status = null;
            }
            const result = await this.$store.dispatch("order/getVendorOrders", {status, offset, limit});
            this.orders = result.data ?? [];
            this.totalOrdersCount = result.total ?? 0;
        }
    },
    mounted() {
        this.fetchOrders();
    },
}
