<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="inquiry-wrapper " v-bind:class="{ 'open-filter-box': filterBoxActive}">
      <div class="add-property-title row align-items-baseline me-auto">
        <div class="col-md-3 col-12"> {{ $t('vendor-orders-title') }}</div>
        <div class="filter-by col-6  d-flex  align-items-center justify-content-end ">
          <div class="d-none d-md-block"> {{ $t('filter-by-status') }}:</div>
          <div class="col-md-6 col-12 ms-2">
            <Dropdown v-model="selectedFilter" :label="$t('choose-status')" :options="orderStatuses"/>
          </div>
        </div>
        <div class="ms-auto col-6 col-md-3 sort-filter d-flex justify-content-end">
          <div class="sort-by me-1 d-none d-md-block">{{ $t('filter-by') }}</div>
          <div class="d-flex align-items-center sort-by-button me-3">
            <div class="me-1" @click="filterBoxActive = !filterBoxActive"> {{ selectedOption.title }}</div>
            <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.66675 1.16667L4.66675 11.8333" stroke="#FF7A00" stroke-linecap="round"/>
              <path d="M0.666749 7.83334L4.66675 11.8333L8.66675 7.83334" stroke="#FF7A00" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="sort-filter-modal ">
            <div class="sort-item" v-for="option in sortingOptions" @click="selectOption(option)"> {{
                option.title
              }}
            </div>
          </div>
        </div>

      </div>

      <div class="mt-2" v-if="orders.length > 0">
        <div class=" labels-order d-none d-md-flex">
          <div class="order-id col-1"> #</div>
          <div class="client-name col-2">{{ $t('vendor-client-name') }}</div>
          <div class="client-phone col-2">{{ $t('vendor-client-phone') }}</div>
          <div class="order-status col-2 ">
            {{ $t('vendor-order-status') }}
          </div>
          <div class="order-amount col-2"> {{ $t('vendor-order-amount') }}</div>
          <div class="date col-2"> {{ $t('vendor-order-date') }}</div>
        </div>

        <div class="inquiry-item" v-for="order in orders">
          <AdminVendorListItem
              :id="order.id"
              :date="order.createdAt"
              :orderstatus="getOrderStatusNameById(order.status)"
              :orderid="order.id"
              :name="order.name"
              :phone="order.phone"
              :orderamount="order.totalSum"/>
        </div>
      </div>
      <div class="alert alert-warning" role="alert" v-else>
        {{ $t('there-are-no-orders-found') }}
      </div>
      <div class="pagination" v-if="totalOrdersCount !== null">
        <Pagination
            :size="'small'"
            :pages="Math.ceil(totalOrdersCount/limit)"
            :route="'AdminPropertyList'"
            @pageChanged="handlePageChange"
        />
      </div>

    </div>
  </div>
</template>
